import { z } from "zod";

export const permission = z.enum([
  "ADHOC_PAYMENT_APPROVE",
  "ADHOC_PAYMENT_SUBMIT",
  "QUEST_REWARDS_EDIT",
  "USER_ADMINISTER",
]);

export const legacySchema = z.object({
  userId: z.string().uuid(),
  adhocSubmit: z.boolean().default(false),
  adhocApprove: z.boolean().default(false),
  rewardsEdit: z.boolean().default(false),
  admin: z.boolean().default(false),
});

export type LegacyPermissions = z.infer<typeof legacySchema>;
export type Permission = z.infer<typeof permission>;
